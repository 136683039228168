import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import "../styles/partners.scss"

const Partners = ({ data }) => (
  <Layout
    seoMetaTags={data?.datoCmsPartnerspage?.seoMetaTags}
    page="Kumppaniksi"
  >
    <div className="partners">
      <div
        className="content"
        dangerouslySetInnerHTML={{
          __html: data.datoCmsPartnerspage.bodyNode.childMarkdownRemark.html,
        }}
      />
    </div>
  </Layout>
)
export const query = graphql`
  query partnersQuery {
    datoCmsPartnerspage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      bodyNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default Partners
